import {
    CheckoutWidget,
    CheckoutWidgetConfiguration,
} from "./widget-api-types";
import { CheckoutWidgetController } from "./widget-controller";

export * from "./widget-api-types";

export function createWidget(elementOrSelector: string | Element, configuration?: CheckoutWidgetConfiguration): CheckoutWidget {
    return new CheckoutWidgetController(import(/* webpackChunkName: "version2" */ "./widget").then((main) => {
        const targetElement = typeof elementOrSelector === "string" ? document.querySelector(elementOrSelector) : elementOrSelector;
        if(!targetElement) {
            throw new Error("Missing target element for nShift checkout widget");
        }
        return new main.CheckoutWidgetImpl(targetElement, configuration);
    }));
}
