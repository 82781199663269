import {
    CheckoutWidget,
    CheckoutWidgetOptions,
    CheckoutWidgetResult,
    EMPTY_CHECKOUT_WIDGET_RESULT
} from "./widget-api-types";

export class CheckoutWidgetController implements CheckoutWidget {
    private widget: CheckoutWidget | undefined;

    private installed = false;
    private localEnabled = true;
    private localOptions: CheckoutWidgetOptions | undefined;
    private localResult: CheckoutWidgetResult | undefined;

    public get enabled(): boolean {
        if(this.widget) {
            return this.widget.enabled;
        }
        return this.localEnabled;
    }

    public get result(): CheckoutWidgetResult {
        if(this.widget) {
            return this.widget.result;
        }
        return this.localResult ?? EMPTY_CHECKOUT_WIDGET_RESULT;
    }

    constructor(widgetPromise: Promise<CheckoutWidget>) {
        widgetPromise.then((widget) => {
            this.widget = widget;
            // Update the actual widget with local state.
            if(this.localOptions && this.localResult) {
                this.widget.updateOptions(this.localOptions, this.localResult);
            } else {
                if(this.localOptions) {
                    this.widget.updateOptions(this.localOptions);
                }
                if(this.localResult) {
                    this.widget.setResult(this.localResult);
                }
            }
            this.localOptions = undefined;
            this.localResult = undefined;
            if(this.localEnabled) {
                this.widget.enable();
            } else {
                this.widget.disable();
            }
            if(this.installed) {
                this.widget.install();
            }
        });
    }

    public install(): void {
        this.installed = true;
        if(this.widget) {
            this.widget.install();
        }
    }

    public uninstall(): void {
        this.installed = false;
        if(this.widget) {
            this.widget.uninstall();
        }
    }

    public enable(): void {
        this.localEnabled = true;
        if(this.widget) {
            this.widget.enable();
        }
    }

    public disable(): void {
        this.localEnabled = false;
        if(this.widget) {
            this.widget.disable();
        }
    }

    public setResult(result: CheckoutWidgetResult): void {
        if(this.widget) {
            this.widget.setResult(result);
        } else {
            this.localResult = result;
        }
    }

    public updateOptions(options: CheckoutWidgetOptions, result?: CheckoutWidgetResult): void {
        if(this.widget) {
            this.widget.updateOptions(options, result);
        } else {
            this.localOptions = options;
            if(result) {
                this.localResult = result;
            }
        }
    }
}
