export interface CheckoutWidgetBase {
    readonly enabled: boolean;
    readonly result: CheckoutWidgetResult;
    setResult: (result: CheckoutWidgetResult) => void;
    uninstall: () => void;
    enable: () => void;
    disable: () => void;
    updateOptions: (options: CheckoutWidgetOptions, result?: CheckoutWidgetResult) => void;
}

export interface CheckoutWidget extends CheckoutWidgetBase {
    install: () => void;
}

export type CheckoutWidgetControl = CheckoutWidgetBase;

export const CHECKOUT_WIDGET_VERSION = "2";
export type CheckoutWidgetVersions = typeof CHECKOUT_WIDGET_VERSION;

export interface CheckoutWidgetConfiguration {
    widgetVersion: typeof CHECKOUT_WIDGET_VERSION;
    theme?: CheckoutWidgetTheme;
    themeOverride?: CheckoutWidgetThemeConfiguration;
    runtimeEnvironment?: CheckoutRuntimeEnvironment;
    widthBreakpoints?: CheckoutWidgetWidthBreakpoints;
    popupContainerElement?: Element;
    resizeCallback?: (size: { w: number, h: number }) => void;
    resultCallback?: (result: CheckoutWidgetResult) => void;
}

export enum CheckoutWidgetTheme {
    NSHIFT_THEME1 = "nshift-theme1"
}

export interface CheckoutWidgetThemeConfiguration {
    showLogos?: boolean;
    showMap?: boolean;
    showPickupPointInfo?: boolean;
    showCategories?: boolean;
    showOriginalPrices?: boolean;
    showDeliveryTime?: boolean;
    showIssues?: boolean;
}

export enum CheckoutRuntimeEnvironment {
    QA = "qa",
    PROD = "prod"
}

export type CheckoutWidgetWidthBreakpoints = { [breakpoint: string]: number };

export enum CheckoutWidgetWidthBreakpoint {
    NARROW = "narrow",
    ULTRA_NARROW = "ultra-narrow"
}

export interface CheckoutWidgetOptions {
    optionsVersion: typeof CHECKOUT_WIDGET_VERSION;
    sessionId: string;
    checkoutId: string;
    checkoutVersion: string;
    language: string;
    options: CheckoutWidgetOption[];
    categories?: CheckoutWidgetCategory[];
    addons?: CheckoutWidgetAddon[];
    fields?: CheckoutWidgetField[];
    issues?: CheckoutWidgetIssue[];
}

export interface CheckoutWidgetOption {
    valid: boolean;
    optionId: string;
    categoryId?: string;
    title: string;
    texts?: string[];
    logoId?: string;
    logoUrl?: string;
    priceDescription: string;
    price: number;
    originalPriceDescription?: string;
    originalPrice?: number;
    taxRate?: number;
    pickupPoints?: CheckoutWidgetPickupPoint[];
    timeSlots?: CheckoutWidgetTimeSlot[];
    addons?: CheckoutWidgetAddon[];
    fields?: CheckoutWidgetField[];
    badges?: CheckoutWidgetBadge[];
    certifications?: CheckoutWidgetCertification[];
    noDefaultPickupPoint?: boolean;
    additionalValues?: { [key: string]: string };
    deliveryTime?: CheckoutWidgetDeliveryTime;
}

export interface CheckoutWidgetCategory {
    categoryId: string;
    title: string;
    showAggregatedPickupPoints?: boolean;
}

export interface CheckoutWidgetPickupPoint {
    pickupPointId: string;
    latitude?: number;
    longitude?: number;
    name: string;
    address1?: string;
    address2?: string;
    postalCode?: string;
    city: string;
    state?: string;
    formattedAddress1?: string;
    formattedAddress2?: string;
    formattedAddress3?: string;
    formattedAddress4?: string;
    formattedAddress5?: string;
    additionalInfo?: string;
    openHoursWeekday?: CheckoutWidgetWeekdayOpenHours[];
    openHoursSpecial?: CheckoutWidgetSpecialOpenHours[];
    deliveryTime?: CheckoutWidgetDeliveryTime;
}

export interface CheckoutWidgetTimeSlot {
    timeSlotId: string;
    description: string;
    descriptionParts: string[];
    earliest: string;
    latest: string;
    cutoff?: string;
    timeZone: string;
    timeSlotToken?: string;
}

export interface CheckoutWidgetDeliveryTime {
    description?: string;
}

export interface CheckoutWidgetWeekdayOpenHours {
    firstWeekday: string;
    lastWeekday: string;
    description: string;
    openingHours: CheckoutWidgetHourRange[];
    closed?: boolean;
}

export interface CheckoutWidgetSpecialOpenHours {
    date: string;
    description: string;
    openingHours: CheckoutWidgetHourRange[];
    closed?: boolean;
}

export interface CheckoutWidgetHourRange {
    opens: string;
    closes: string;
    description: string;
}

export interface CheckoutWidgetAddon {
    addonId: string;
    title: string;
    preselected?: boolean;
    mandatory?: boolean;
    priceDescription: string;
    price: number;
    originalPriceDescription?: string;
    originalPrice?: number;
    oneOf?: string[];
    atLeastOneOf?: string[];
    exclude?: string[];
    dependency?: string[];
    fields: CheckoutWidgetField[];
    additionalValues?: { [key: string]: string };
}

export interface CheckoutWidgetField {
    fieldId: CheckoutWidgetFieldId;
    title: string;
    initialValue?: string;
    mandatory?: boolean;
    hidden?: boolean;
    min?: number;
    max?: number;
    pattern?: string;
    valueItems?: CheckoutWidgetListItem[];
}

export enum CheckoutWidgetFieldId {
    MOBILE = "MOBILE",
    PHONE = "PHONE",
    EMAIL = "EMAIL",
    DOOR_CODE = "DOOR_CODE",
    DELIVERY_INSTRUCTION = "DELIVERY_INSTRUCTION",
    DELIVERY_TIME = "DELIVERY_TIME",
    CONTACT_NAME = "CONTACT_NAME"
}

export const FIELD_ID_SET = [
    CheckoutWidgetFieldId.MOBILE,
    CheckoutWidgetFieldId.PHONE,
    CheckoutWidgetFieldId.EMAIL,
    CheckoutWidgetFieldId.DOOR_CODE,
    CheckoutWidgetFieldId.DELIVERY_INSTRUCTION,
    CheckoutWidgetFieldId.DELIVERY_TIME,
    CheckoutWidgetFieldId.CONTACT_NAME
];

export interface CheckoutWidgetBadge {
    badgeId: string;
    title: string;
    description: string;
    color?: string;
    iconId?: string;
    iconUrl?: string;
    url?: string;
}

export interface CheckoutWidgetCertification {
    certificationId: string;
    title: string;
    description: string;
    color?: string;
    iconId?: string;
    iconUrl?: string;
    url?: string;
}

export interface CheckoutWidgetListItem {
    name: string;
    value: string;
}

export interface CheckoutWidgetIssue {
    issueCode: string;
    issueSeverity: string;
    location: string;
    description: string;
}

export interface CheckoutWidgetResult {
    valid: boolean;
    sessionId: string;
    checkoutId: string;
    checkoutVersion: string;
    optionId: string;
    pickupPointId?: string;
    timeSlotId?: string;
    addons: CheckoutWidgetAddonResult[];
    fields: CheckoutWidgetFieldResult[];
    optionTitle: string;
    optionPrice: number;
    totalPrice: number;
    totalOriginalPrice: number;
    taxRate?: number;
    additionalValues?: { [key: string]: string };
    resultsVersion: typeof CHECKOUT_WIDGET_VERSION,
    widgetVersion: CheckoutWidgetVersions;
}

export interface CheckoutWidgetAddonResult {
    addonId: string;
    price: number;
    originalPrice: number;
    fields: CheckoutWidgetFieldResult[];
    additionalValues?: { [key: string]: string };
}

export interface CheckoutWidgetFieldResult {
    fieldId: string;
    value: string;
}

export const EMPTY_CHECKOUT_WIDGET_RESULT: CheckoutWidgetResult = {
    valid: false,
    sessionId: "",
    checkoutId: "",
    checkoutVersion: "",
    optionId: "",
    pickupPointId: "",
    addons: [],
    fields: [],
    optionTitle: "",
    optionPrice: 0,
    totalOriginalPrice: 0,
    totalPrice: 0,
    resultsVersion: CHECKOUT_WIDGET_VERSION,
    widgetVersion: CHECKOUT_WIDGET_VERSION
};

export const CHECKOUT_WIDGET_THEME1_CONFIGURATION: CheckoutWidgetThemeConfiguration = {
    showCategories: true,
    showLogos: true,
    showMap: true,
    showOriginalPrices: true,
};
